<template>
	<div id="project" v-on:click="onSelected">
		<div class="img-container">
			<img v-bind:src="thumbnail(project.thumb)" />
		</div>
		<div class="title">{{project.title}}</div>
	</div>
</template>

<script>
export default {
	name: 'Project',
	props: ['project'],
	data() {
		return {
		}
	},
	methods: {
		onSelected() {
			console.log("project clicked: "+this.project.title);
			this.$emit('projectClicked', this.project);
		},
		thumbnail: function(path) {
			return require("@/assets/" + path);
		}
	},
	computed: {
	}

}
</script>

<style scoped>

@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400&display=swap');

#project {
	margin: 0 25px 25px 0;
	width: 270px;
}
#project:hover {
	cursor: pointer;
}

.img-container {
	height: 180px;
	overflow: hidden;
}

.img-container img {
	width: 270px;
	height: 180px;
	/*transition: transform .2s;*/
	transition: transform .2s, filter .2s ease-in-out;
	filter: brightness(100%) grayscale(25%);
}

.img-container:hover img {
	transform: scale(1.05);
	filter: brightness(110%) grayscale(0);
}

.title {
	font-family: 'Roboto Condensed', sans-serif;
	font-weight: 400;
	font-size: 14px;
	padding-top: 4px;
}
</style>