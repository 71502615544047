<template>
	<div id="projects">

		<LightBox
			v-bind:project="selected"
			v-if="modal"
			v-on:close="modal=false"
		/>

		<div class="projectWrapper" v-bind:key="project.id" v-for="project in projects">
			<Project v-if="project.gallery" v-bind:project="project" v-on:projectClicked="showDetails"/>
		</div>

	</div>
</template>

<script>
import Project from '@/components/Project.vue'
import LightBox from '@/components/LightBox.vue'
import json from "@/assets/projects.json";

export default {
	name: 'Projects',
	components: {
		Project,
		LightBox
	},
	data() {
		return {
			projects: json,
			//projects: []
			selected: null,
			modal: false
		}
	},
	methods: {
		showDetails(project) {
			this.selected = project;
			this.modal = true;
		}
	},
	created() {
		/*
		let url = './assets/projects.json' //therefore the file has to be inside the "public"-folder of vue-app
		fetch(url)
			.then(res => res.json())
			.then(data => {
				//console.log(data)
				this.projects = data;
			})
			.catch(err => console.error(err));
		*/
	}
}
</script>

<style scoped>
	#projects {
		display: flex;
		flex-wrap: wrap;
		/*flex: 1 1 auto;
		justify-content: flex-start;
		align-content: flex-start;
		overflow-y: auto;*/
	}
	.projectWrapper {
		/*display: flex;
		flex-wrap: wrap;*/
	}
</style>