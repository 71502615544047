<template>
  <div id="header">
	<h1 class="title">Flimmerwerk</h1>

	<div class="menu">
		<router-link to="/projects" 
			class="menu-item"
			active-class="menu-item-active">projects
		</router-link>
		<router-link to="/about" 
			class="menu-item"
			active-class="menu-item-active">about me
		</router-link>
		<router-link to="/contact" 
			class="menu-item"
			active-class="menu-item-active">contact
		</router-link>
		<!--<div class="menu-item" 
			:class="{'menu-item-active': selectedMenuItem === menuItems[0]}"
			v-on:click="onMenuItemClicked(0)">{{menuItems[0]}}
		</div>
		<div class="menu-item" 
			:class="{'menu-item-active': selectedMenuItem === menuItems[1]}"
			v-on:click="onMenuItemClicked(1)">about me
		</div>
		<div class="menu-item" 
			:class="{'menu-item-active': selectedMenuItem === menuItems[2]}"
			v-on:click="onMenuItemClicked(2)">contact
		</div>-->
	</div>

  </div>
</template>

<script>
export default {
	name: 'Header',
	data() {
		return {
			//selectedMenuItem: '',
			//menuItems: ['projects', 'about me', 'contact'],
			//routerLinks: ['/', '/about/', '/contact/']
		}
	},
	methods: {
		onMenuItemClicked(index) {
			//this.selectedMenuItem = this.menuItems[index];
			console.log("clicked index: "+index)
			//this.$router.push({path: this.routerLinks[index]});
		}
	},
	created() {
		//this.selectedMenuItem = this.menuItems[0];
	}
}
</script>

<style scoped>

#header {
	padding: 0;
	margin: 0 0 40px 0;
	background: white;
	border-bottom: 1px solid #666666;
}

#header .title {
	margin-top: 40px;
    margin-bottom: 20px;
    color: #999;
	font-family: 'Roboto', sans-serif;
	font-size: 2em;
	text-align: left;
}

#header .menu {
	display: flex;
	/*align-items: center;*/
	/*justify-content: center;
	vertical-align: middle;*/
}

#header .menu-item {
	/*flex: 1;/*
	/*margin: 10px 20px 10px 20px;*/
	padding: 10px 40px 10px 0px;
	background: none;
	font-family: 'Roboto', sans-serif;
	font-style: 700; /*500: Medium, 700: Bold*/
	font-size: 14px;
	text-decoration: none;
	color: #999;
	/*background-color: #eeeeee;
	border-right: 2px solid white;*/
}	

#header .menu-item:hover {
	/*background: #333;
	color: white;*/
	color: #333;
	cursor: pointer;
}

#header .menu-item-active {
	cursor: default;
	background: none;
	color:#234;
	/*background: #333;
	color: white;*/
}

</style>