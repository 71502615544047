<template>
    <div id="app">
        <div class="mainContainer">
            <Header />
            <router-view />
            <Footer />
        </div>
    </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
    name: "App",
    components: {
        Header,
        Footer
    }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap");

#app {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    /*align-items: stretch;*/
    margin-top: 0px;
    margin-bottom: 0px;
}

.mainContainer {
    background: white;
    min-width: 600px;
    max-width: 930px;
    /*width: 930px;*/
    display: block;
    margin: auto;
    margin-top: 0px;
    padding-left: 20px;
    padding-right: 20px;
}
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html,
body {
    height: 100%;
    margin: 0;
}

body {
    background: #eee;
    font-family: "Open Sans", "Roboto", sans-serif;
    font-style: 400;
    /*font-family: 'Roboto', Helvetica, sans-serif;
	font-style: 500;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    /*color: #333;*/
    font-size: 14px;
    text-align: left;
}

h1,
h2,
h3,
h4 {
    font-family: "Roboto", sans-serif;
    margin-top: 28px;
    margin-bottom: 4px;
}
h1 {
    font-style: 700; /*Bold*/
    font-weight: 700;
    font-size: 22px; /*24px;*/
    color: #999;
}
h2 {
    font-style: 700; /*Bold*/
    font-weight: 700;
    font-size: 18px;
    color: #444;
}
h3 {
    font-style: 500; /*Medium*/
    font-weight: 500;
    font-size: 16px;
    color: #555;
}
h4 {
    font-style: 500; /*Medium*/
    /*font-weight: 500;*/
    font-size: 14px;
    color: #666;
}

p {
    line-height: 1.5em;
    margin-bottom: 8px;
}

a:link {
    color: #333;
    text-decoration: underline;
}
a:visited {
    color: #333;
    text-decoration: underline;
}
a:hover {
    color: #fff;
    text-decoration: none;
    background-color: #333;
}
a:active {
    color: #fff;
    text-decoration: none;
    background-color: #333;
}

/*hr {
	border: 1px solid #999;
	margin-bottom: 26px;
}*/

/*
@media screen and (max-width: 909px){
  .container {
    width: 620px;
  }
}

@media screen and (max-width: 606px){
  .container {
    width: 320px;
  }
}
*/
</style>
