<template>
	<div id="lightbox">

		<div ref="bg" class="modal-wrapper" v-on:click="onClose" >
			
			<div class="modal-container">
		
				<div ref="closeBtn" class="closeBtn" v-on:click="onClose" title="close">×</div>
				
				<div class="imgContainer">
					
					<img ref="projectImg" class="modal-image" v-bind:src="getCurrentImagePath()" />

					<div class="imgNav" v-if="project.images != undefined">
						<div v-show="(currentIndex > 0)" class="navBtn prevBtn" v-on:click="onPrev" title="previous"><span>
								<svg fill="white" x="0px" y="0px" width="100%" height="100%" viewBox="0 0 512 512"><path d="M213.7,256L213.7,256L213.7,256L380.9,81.9c4.2-4.3,4.1-11.4-0.2-15.8l-29.9-30.6c-4.3-4.4-11.3-4.5-15.5-0.2L131.1,247.9 c-2.2,2.2-3.2,5.2-3,8.1c-0.1,3,0.9,5.9,3,8.1l204.2,212.7c4.2,4.3,11.2,4.2,15.5-0.2l29.9-30.6c4.3-4.4,4.4-11.5,0.2-15.8 L213.7,256z"></path></svg>
							</span>
						</div>
						<div v-show="(currentIndex < project.images.length-1)" class="navBtn nextBtn" v-on:click="onNext" title="next"><span>
								<svg fill="white" x="0px" y="0px" width="100%" height="100%" viewBox="0 0 512 512"><path d="M298.3,256L298.3,256L298.3,256L131.1,81.9c-4.2-4.3-4.1-11.4,0.2-15.8l29.9-30.6c4.3-4.4,11.3-4.5,15.5-0.2l204.2,212.7 c2.2,2.2,3.2,5.2,3,8.1c0.1,3-0.9,5.9-3,8.1L176.7,476.8c-4.2,4.3-11.2,4.2-15.5-0.2L131.3,446c-4.3-4.4-4.4-11.5-0.2-15.8 L298.3,256z"></path></svg>
							</span>
						</div>
					</div>
					
					<div v-if="(project.images.length > 1)" class="counter">{{(currentIndex+1) +' / '+(project.images.length)}}</div>

				</div>
				
				<div class="modal-text">
					<h3 >{{project.title}}</h3>
					<p>{{project.description}}</p>
					<p><span class="bold">Scope: </span>{{project.scope}}</p>
					<p v-if="project.link != undefined">
						<span class="bold">Site: </span><a :href="project.link" target="_blank">{{project.site}}</a>
						<span v-if="project.site2 != undefined"> and 
							<a v-if="project.link2 != undefined" :href="project.link2" target="_blank">{{project.site2}}</a>
							<span v-else>{{project.site2}}</span>
						</span>
					</p>
					<p v-else>
						<span class="bold">Site: </span>{{project.site}}
					</p>
					<!--<p><span class="bold">Site: </span>{{project.site}}</p>
					<p v-if="project.link != undefined">
						<span class="bold">Link: </span><a :href="project.link" target="_blank">{{project.link}}</a>
						<span v-if="project.link2 != undefined">, </span><a v-if="project.link2 != undefined" :href="project.link2" target="_blank">{{project.link2}}</a>
					</p>-->
				</div>
		
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "LightBox",
	props: [
			"project"
		],
	data: function() {
		return {
			currentIndex: 0
		}
	},
	computed: {
		//
	},
	methods: {
		getCurrentImagePath() {
			return require("@/assets/" + this.project.imgPath + this.project.images[this.currentIndex]);
		},
		onClose(event) {
			if(event.target == this.$refs.bg || event.target == this.$refs.closeBtn) {
				this.$emit('close');
			}
		},
		onPrev() {
			if(this.currentIndex > 0) {
				this.currentIndex--;
				//this.$refs.projectImg.src = require("@/assets/" + this.project.images[this.currentIndex]);
			}
		},
		onNext() {
			if(this.currentIndex < this.project.images.length-1) {
				this.currentIndex++;
				//this.$refs.projectImg.src = require("@/assets/" + this.project.images[this.currentIndex]);
			}
		}
	},
	mounted: function() {
		this.currentIndex = 0;
	}
}
</script>

<style scoped>
#lightbox {
    position: fixed;
    z-index: 9990;
	top: 0;
	left: 0;
    width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.85);
	/*transition: opacity 0.5s ease;
	animation-name: zoom;
    animation-duration: 0.3s;*/
	display: table;
	/*transition: transform 0.3s;
	transform: scale(1.5);*/
}
.modal-wrapper {
	display: table-cell;
	vertical-align: middle;
}
.modal-container { 
	width: 80%;
	max-width: 800px;
	margin: 0px auto;
	padding: 0px 50px;
	background-color: #fff;
	border-radius: 2px;
	box-shadow: 10px 10px 8px rgba(0, 0, 0, 0.5);
	/*transition: all 0.3s ease;*/
	/*overflow-y: scroll;*/ /* Show vertical scrollbar */
}
.imgContainer {
	position: relative;
}
.modal-image {
    display: block;
    width: 100%;
}
.modal-text {
    margin: auto;
    display: block;
    width: 100%;
    text-align: left;
    color: #666;
	padding-bottom: 20px;
}
.bold {
	font-family: 'Roboto', sans-serif;
	font-weight: 700;
	font-size: 14px;
	color: #666;
}
.closeBtn {
	/*display: inline-block;*/
    text-align: right;
    color: #666;
    font-size: 40px;
    font-weight: bold;
	user-select: none;
    transition: 0.3s;
}
.closeBtn:hover, .closeBtn:focus {
	text-decoration: none;
	color: #333;
	cursor: pointer;
	/*transform: rotate(90deg);*/
}
.imgNav {
	display: flex;
	align-items: center;
    align-content: space-between;
	justify-content: space-between;
}
.navBtn {
	background: rgba(0, 0, 0, 0.4);
	color: #fff;
    border: none;
    cursor: pointer;
    outline: none;
	padding: 10px;
    position: absolute;
    top: 45%;
    user-select: none;
    width: 55px;
	height: 65px;
	opacity: 0.5;
	transition: 0.3s;
}
.navBtn:hover {
	opacity: 1.0;
}
.prevBtn {
    left: 0;
	padding-right: 10px;
    border-top-right-radius: 10%;
    border-bottom-right-radius: 10%;
}
.nextBtn {
	right: 0;
	padding-left: 10px;
    border-top-left-radius: 10%;
    border-bottom-left-radius: 10%;
}

.nextBtn:hover, .prevBtn:hover, .nextBtn:focus, .prevBtn:focus {
	text-decoration: none;
	cursor: pointer;
}

.counter {
	text-align: right;
	color: #666;
	margin-bottom: -14px;
}

#lightbox a:link {
	color: #333;
	text-decoration: none;
	border-bottom: #333 1px solid;
	
}
#lightbox a:visited {
	color: #333;
	text-decoration: none;
	border-bottom: #333 1px solid;
}
#lightbox a:hover {
	color: #fff;
	text-decoration: none;
	background-color: #333;
}
#lightbox a:active {
	color: #fff;
	text-decoration: none;
	background-color: #333;
}

</style>