<template>
	<div id="footer">
		<p>
			<router-link to="/imprint">imprint & disclaimer</router-link> | copyright © 2021
		</p>
	</div>
</template>

<script>
export default {
	name: 'Footer'
}
</script>

<style scoped>

#footer {
	margin:30px 0 12px 0;
    font-family: 'Roboto', sans-serif;
    font-style: 500;
    font-size: 12px;
    color: #333;
    border-top: 1px solid #999;
}

p {
	line-height: 1.5em;
    margin: 8px 0 8px 0;
}
</style>